.modal {
  width: auto;
  height: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--fontFamily), "Nunito Sans";
}

.modal .zerosbutton {
  background-color: var(--buttonBgColor) !important;
  color: white !important;
  font-family: var(--fontFamily), "Nunito Sans" !important;
}

.modal .zerosbutton:hover {
  background-color: var(--buttonBgHoverColor) !important;
  color: white !important;
}

.modal .zeroslink {
  color: var(--buttonBgColor) !important;
  font-family: var(--fontFamily), "Nunito Sans" !important;
}

.modal *:not(.zerosbutton, .zeroslink, > .footer) {
  color: var(--textColor) !important;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}
