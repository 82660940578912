.card {
  position: absolute;
  width: 451px;
  /* height: "290px"; */
  font-family: "Proxima Nova";
  background: #ffffff;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin: 36px 40px;
}

.icon {
  margin-bottom: 28px;
}

.title {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: #222222;
  margin-bottom: 8px;
}

.description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  color: #444444;
  margin-top: 8px;
  margin-bottom: 28px;
}

.description p {
  margin: 4px 0;
}

.horizontal {
  width: 100%;
}

.return {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #7d7d7d;
  margin-top: 0;
  margin-bottom: 5px;
}

.link {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #0071f2;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.button {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  padding: 14px auto;
  width: 114px;
  min-height: 44px;
  justify-content: center;
  background-color: #0071f2;
  color: #fafafa;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  margin-top: 0;
  margin-bottom: 48px;
}
