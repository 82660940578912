.card {
  position: absolute;
  width: 601px;
  /* height: "290px"; */
  font-family: "Proxima Nova";
  background: #ffffff;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.content {
  display: "flex";
  flex-direction: "column";
  justify-content: "start";
  margin: 36px 40px;
}

.title {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: #222222;
  margin-bottom: 16px;
}

.description {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #222222;
  opacity: 0.8;
  margin-bottom: 24px;
}

.field {
  width: 100%;
  background: #ffffff;
  border-radius: 5px;
  padding: 12px;
  margin-bottom: 24px;
  border: 1px solid #d9d9d9;
}

.controls {
  display: flex;
  justify-content: end;
  gap: 20px;
  margin-bottom: 50px;
}

.privacy {
  padding: 8px 6px;
  width: 100%;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #222222;
  opacity: 0.8;
  margin-bottom: 45px;
}

.link {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  padding: 14px 24px;
  width: 80px;
  justify-content: center;
  background-color: #ffffff;
  color: #0071f2;
  cursor: pointer;
  border-radius: 5px;
  border: none;
}

.button {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  padding: 14px 24px;
  width: 99px;
  justify-content: center;
  background-color: #0071f2;
  color: #fafafa;
  cursor: pointer;
  border-radius: 5px;
  border: none;
}
