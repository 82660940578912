#root {
  font-family: 'Nunito Sans', sans-serif;
}

.sv-title {
  font-family: 'Nunito Sans', sans-serif;
  background-color: rgba(255, 255, 255, 0);
  font-weight: 700;
}

.sv-question__header--location--top {
  font-family: 'Nunito Sans', sans-serif;
}
.sv-question__content {
  font-family: 'Nunito Sans', sans-serif;
  margin-left: 0em !important;
}

.sv-footer__complete-btn {
  background-color: #3688e8 !important;
  border-radius: 5pt;

  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
}
/*---- Check Box -------*/
.sv-checkbox__svg {
  width: 18px;
  height: 18px;
}


.sv-title.sv-question__title.sv-question__title--answer {
  background-color: transparent;
}

.sv-root-modern .sv-checkbox--checked .sv-checkbox__svg {
  background-color: #2c9fdd !important;
  border-color: white;
  fill: rgb(255, 255, 255);
}
.sv-root-modern .sv-checkbox__svg {
  border-color: #e5e5e5 !important;
  fill: transparent;
  border-width: 2px;
}

/*---- Radio Button-----*/
.sv-radio__svg {
  width: 18px;
  height: 18px;
}
.sv-radio--checked .sv-radio__svg {
  background-color: white;
  border-color: #2c9fdd !important;
  border-width: 1px !important;
  fill: #2c9fdd !important;
}

.sv-root-modern .sv-radio__svg {
  border-width: 1px !important;
}

.sv-root-modern .sv-item__control:focus + .sv-item__decorator {
  border-color: white !important;
}

.sv-string-viewer {
  font-weight: 600;
}

/* DropDown*/
.sv-dropdown{
  min-width: 300px;
  width: fit-content;
  border: 0.06em solid rgb(212, 212, 212) !important;
}

/* text*/
.sv-text{
  width: 800px;
  border: 0.07em solid #E3E3E3;
}


.video-header{
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;

  /* Gray 2 */
  color: #4F4F4F;
}


.video-des{
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #5B5959;
}

.share-btn{
  background: #3688E8;
  border: 1px solid #3688E8;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 88px;
  height: 40px;
  color: white;
  font-weight: 300;
}

.sv-body__page, .sv-body__footer {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.action-btn {
  background-color: #3688e8 !important;
  border: 1px solid #3688E8;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color:white;
  width: 190px;
  height: 50px;
  font-weight: 300;
}

.video-container{
  width:100px;
  height:100px;
}

.sv-row__question:first-child:last-child {
  flex: none !important;
  height: unset;
  overflow: hidden !important;
}

.sv-question .sv-selectbase {
  margin-bottom: 0px !important;
}

.sv-root-modern input.sv-text, textarea.sv-comment, select.sv-dropdown {
  color: rgb(64, 64, 64);
  background-color: transparent;
  margin-left: 0.6em !important;
}
